interface GetURLParams {
  productId: string | number
  versionId?: string | number
  profileId?: string | number
  collectionId?: string | number
}

export const RoutesManager = {
  myProducts: {
    root: {
      pattern: '/products',
      getURL: () => '/products',
    },
  },
  productsV2: {
    root: {
      pattern: '/products/v2',
      getURL: () => '/products/v2',
    },
  },
  products: {
    root: {
      pattern: '/products/:productId/*',
      getURL: ({ productId }: Pick<GetURLParams, 'productId'>) => `/products/${productId}`,
    },
    details: {
      pattern: '/products/:productId/details/*',
      getURL: ({ productId }: Pick<GetURLParams, 'productId'>) => `/products/${productId}/details`,
    },
    profiles: {
      root: {
        pattern: '/products/:productId/profiles',
        getURL: ({ productId }: Pick<GetURLParams, 'productId'>) => `/products/${productId}/profiles`,
      },
      update: {
        pattern: '/products/:productId/profiles/:profileId/*',
        getURL: ({ productId, profileId }: GetURLParams) => `/products/${productId}/profiles/${profileId}`,
      },
    },
    versions: {
      root: {
        pattern: '/products/:productId/versions',
        getURL: ({ productId }: Pick<GetURLParams, 'productId'>) => `/products/${productId}/versions`,
      },
      launch: {
        pattern: '/products/:productId/versions/:versionId/launch',
        getURL: ({ productId, versionId }: GetURLParams) => `/products/${productId}/versions/${versionId}/launch`,
      },
      update: {
        pattern: '/products/:productId/versions/:versionId/*',
        getURL: ({ productId, versionId }: GetURLParams) => `/products/${productId}/versions/${versionId}`,
      },
    },
    collaboration: {
      pattern: '/products/:productId/collaboration',
      getURL: ({ productId }: Pick<GetURLParams, 'productId'>) => `/products/${productId}/collaboration`,
    },
  },
  overview: {
    root: {
      pattern: '/overview',
      getURL: () => '/overview',
    },
  },
  admin: {
    root: {
      pattern: '/admin',
      getURL: () => '/admin',
    },
  },
  collections: {
    root: {
      pattern: '/collections',
      getURL: () => '/collections',
    },
    details: {
      pattern: '/collections/:collectionId/*',
      getURL: ({ collectionId }: Pick<GetURLParams, 'collectionId'>) => `/collections/${collectionId}`,
    },
  },
}
