import styled from '@emotion/styled'
import { WppActionButton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const PageWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  padding: 24px 0;
`

export const PageContent = styled(Flex)`
  height: 100%;
`

export const DeleteCollectionButton = styled(WppActionButton)`
  flex-shrink: 0;
`
