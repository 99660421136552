import { WppTypography } from '@platform-ui-kit/components-library-react'

import { CustomCellRenderer, TableEmptyCell } from 'ui-base/dataTable/DataTable'

import * as S from 'ui-base/agGridTable/AgGridTable.styled'

export const cellRenderer = ({
  value,
  data,
  cell,
  customLoading,
  isEmptyValue,
  customEmptyValue,
  isLoading,
  ...rest
}: CustomCellRenderer): JSX.Element => {
  const emptyComponent = customEmptyValue || TableEmptyCell
  if (value) {
    return isEmptyValue ? emptyComponent : <WppTypography type="s-body">{value}</WppTypography>
  }
  if (isLoading || (data?.__loading && cell)) {
    return customLoading || <S.Skeleton rowHeight={rest.node.rowHeight} actualWidth={rest.column.actualWidth} />
  }
  return isEmptyValue ? emptyComponent : cell
}
