import { useCallback } from 'react'

import { useAppData } from 'hooks//useAppData'
import { AccessActions } from 'types/permissions/enums'
import { AppDTO } from 'types/products/product'

/**
 * Hook returns a callback which checks if every requiredAction is present in productAccessAction
 * or in product data, in the case of a details page.
 *
 * @param {Object} [options] - Optional parameters.
 * @param {AccessActions[]} [options.accessAction] - Array of AccessActions to check against.
 * @param {AppDTO} [options.product] - AppDTO object representing the product.
 */
export const useIsActionPermitted = ({
  accessAction,
  product,
}: { accessAction?: AccessActions[]; product?: AppDTO } = {}) => {
  const { product: productContext } = useAppData()
  const productToVerify = product || productContext
  const actionsSource = accessAction || productToVerify?.accessActions

  const isActionPermitted = useCallback(
    (requiredActions?: AccessActions[]) =>
      requiredActions?.every(requiredAction => !!actionsSource?.find(action => action === requiredAction)),
    [actionsSource],
  )

  return {
    isActionPermitted,
  }
}
