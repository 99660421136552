import { WppButton, WppIconPlus } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

interface Props {
  handleOpenCreateCollectionModal: () => void
  'data-testid'?: string
}

export const AddCollectionButton = ({ 'data-testid': dataTestId, handleOpenCreateCollectionModal }: Props) => {
  const { t } = useTranslation(['collections'])

  return (
    <WppButton
      variant="secondary"
      onClick={handleOpenCreateCollectionModal}
      data-testid={dataTestId || 'add-collection-button'}
    >
      <WppIconPlus slot="icon-start" />
      {t('collections|add_collection')}
    </WppButton>
  )
}
