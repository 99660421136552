import { ICellRendererParams, INoRowsOverlayParams, IRowNode, SortModelItem, ValueGetterFunc } from 'ag-grid-community'

import { cellRenderer as DefaultCellRender } from 'ui-base/agGridTable/components/cellRenderer'
import { ColDef } from 'ui-base/dataTable/InfiniteTable/InfiniteTable'
import { isString } from 'utils/common'

export const DEFAULT_CACHE_OVERFLOW_SIZE = 5
export const DEFAULT_INITIAL_ROW_COUNT = 20
export const DEFAULT_CACHE_BLOCK_SIZE = 40

export type NoRowsOverlayComponent<TData = any> = (props: INoRowsOverlayParams<TData>) => JSX.Element

export type TableInfiniteLoader<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel: SortModelItem[]
}) => Promise<{ data: TData[]; totalRowsCount: number }>

export type TableInfiniteOnLoadError = (error: unknown) => void
export type TableInfiniteOnLoadSuccess<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel: SortModelItem[]
  data: TData[]
  totalRowsCount: number
  /**
   * Indicates if the loader returned 0 items for the first page.
   */
  isEmptyDataSource: boolean
}) => void

export function getValueGetter<TData = any>(valueGetter: string | ValueGetterFunc<TData>): ValueGetterFunc<TData> {
  return function (params) {
    const { data, node } = params

    if (node && isLoadingMoreRow({ data, node })) {
      return null
    }

    return isString(valueGetter) ? data![valueGetter as keyof typeof data] : valueGetter(params)
  }
}

export function isLoadingMoreRow<TData = any>({ data, node }: { data?: TData; node: IRowNode<TData> }) {
  return !data && !node.group && !node.isExpandable()
}

export function DefaultLoadingCellRenderer<TData = any>(params: ICellRendererParams<TData>) {
  return <DefaultCellRender {...params} isLoading />
}

export function getCellRenderer<TData = any>(colDef: ColDef<TData>): NonNullable<ColDef<TData>['cellRenderer']> {
  const {
    cellRenderer: CellRenderer = DefaultCellRender,
    loadingCellRenderer: LoadingCellRenderer = DefaultLoadingCellRenderer,
  } = colDef

  return function (params) {
    const { value, valueFormatted, data, node } = params

    return isLoadingMoreRow<TData>({ data, node }) ? (
      <LoadingCellRenderer {...params} />
    ) : (
      <>{CellRenderer ? <CellRenderer {...params} /> : valueFormatted || value}</>
    )
  }
}
