import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactElement } from 'react'

import { Flex } from 'ui-base/flex/Flex'

import * as S from 'ui-base/emptyState/EmptyState.styled'

type Props = {
  message?: {
    title?: string
    subtitle?: string
    subtitleLink?: {
      text: string
      url?: string
    }
  }
  showImage?: boolean
  image?: ReactElement
  margin?: string
} & JSX.IntrinsicElements['div']

export const EmptyState = ({ message, showImage, margin, image, children, ...rest }: Props) => {
  const showSubtitle = message?.subtitle || message?.subtitleLink?.text
  return (
    <S.Root align="center" direction="column" gap={24} margin={margin} {...rest}>
      {showImage && (image || <S.EmptyFolder />)}
      <Flex direction="column" align="center" gap={8}>
        {message?.title && (
          <WppTypography type="m-strong" data-testid="empty-state-title">
            {message.title}
          </WppTypography>
        )}
        {showSubtitle && (
          <S.SubtitleWrapper>
            {message?.subtitle && (
              <S.Subtitle type="s-body" data-testid="empty-state-subtitle">
                {message.subtitle}
              </S.Subtitle>
            )}
            {message.subtitleLink?.text && (
              <S.SubtitleLink
                href={message.subtitleLink?.url!}
                rel="noopener noreferrer"
                target="_blank"
                data-testid="empty-state-link"
              >
                <S.Subtitle type="s-body">{message.subtitleLink?.text}</S.Subtitle>
              </S.SubtitleLink>
            )}
          </S.SubtitleWrapper>
        )}
      </Flex>
      {children}
    </S.Root>
  )
}
