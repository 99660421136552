import styled from '@emotion/styled'
import { WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'
import { Tooltip } from 'ui-base/tooltip/Tooltip'

export const SIDE_CONTENT_MARGIN = 40
export const GRID_SIDE_PADDING = 8

export const HeaderWrapper = styled(Flex)`
  max-width: calc(var(--wpp-os-content-max-width) - ${SIDE_CONTENT_MARGIN * 2}px - ${GRID_SIDE_PADDING * 2}px);
  gap: 24px;
`

export const Divider = styled(WppDivider)`
  height: 24px;
  width: 1px;
  background-color: var(--wpp-grey-color-300);
`

export const CollectionNameTooltip = styled(Tooltip)`
  min-width: 0;
`

export const TypographyGrey = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`
