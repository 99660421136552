import { WppGrid, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useInfiniteCollectionsApi } from 'api/collections/queries/useInfiniteCollectionsApi'
import { PageSizes } from 'constants/pageSizes'
import { useCustomDialog } from 'hooks/useCustomDialog'
import { AddCollectionButton } from 'pages/collections/common/addCollectionButton/AddCollectionButton'
import { CreateCollectionSideModal } from 'pages/collections/common/createCollectionSideModal/CreateCollectionSideModal'
import { EmptyState } from 'ui-base/emptyState/EmptyState'
import { Flex } from 'ui-base/flex/Flex'
import { NoTableContentSvg } from 'ui-base/svgIcons/emptyState/assets/NoTableContentSvg'
import { Breadcrumbs } from 'ui-shared/breadcrumbs/Breadcrumbs'
import { CollectionCard } from 'ui-shared/collectionCard/CollectionCard'
import { CollectionCardLoadingState } from 'ui-shared/collectionCard/components/CollectionCardLoadingState'

import * as S from 'pages/collections/list/CollectionsPage.styled'

export const CollectionsPage = () => {
  const { t } = useTranslation(['collections'])
  const {
    open: isCreateCollectionModalOpen,
    handleOpen: handleOpenCreateCollectionModal,
    handleClose: handleCloseCreateCollectionModal,
  } = useCustomDialog()

  const {
    data: collections,
    isLoading: isCollectionsLoading,
    isFetching: isCollectionsFetchingNextPage,
    isRefetching: isCollectionsRefetching,
  } = useInfiniteCollectionsApi({
    params: { size: PageSizes.PAGINATED_LOAD_ALL },
  })
  const isCollectionsInitialLoading = isCollectionsLoading && !isCollectionsFetchingNextPage && !isCollectionsRefetching
  const isEmpty = !collections.length && !isCollectionsLoading

  return (
    <S.PageWrapper direction="column" gap={24}>
      <Breadcrumbs />

      <Flex justify="between" align="center">
        <Flex gap={12} align="center">
          <WppTypography type="2xl-heading" data-testid="collections-title">
            {t('collections|collections')}
          </WppTypography>
          <WppTag variant="neutral" label={`${collections.length || 0}`} data-testid="collections-count-tag" />
        </Flex>
        {!isEmpty && <AddCollectionButton handleOpenCreateCollectionModal={handleOpenCreateCollectionModal} />}
      </Flex>

      {isEmpty && (
        <EmptyState
          message={{
            title: t('collections|list.no_collections.title'),
          }}
          image={<NoTableContentSvg />}
          margin="auto"
          showImage
          data-testid="empty-collections-state"
        >
          <AddCollectionButton
            handleOpenCreateCollectionModal={handleOpenCreateCollectionModal}
            data-testid="empty-state-add-collection-button"
          />
        </EmptyState>
      )}

      {!isEmpty && (
        <S.CollectionsCardsWrapper direction="row" container fullWidth all={24} data-testid="collection-cards-wrapper">
          {isCollectionsInitialLoading
            ? Array.from({ length: PageSizes.PAGINATED_COLLECTIONS_SECTION_SIZE }).map((_, index) => (
                <WppGrid item all={8} xl={6} key={index}>
                  <CollectionCardLoadingState />
                </WppGrid>
              ))
            : collections.map(collection => (
                <WppGrid item all={8} xl={6} key={collection.id}>
                  <CollectionCard collection={collection} />
                </WppGrid>
              ))}
        </S.CollectionsCardsWrapper>
      )}

      <CreateCollectionSideModal
        isCreateCollectionModalOpen={isCreateCollectionModalOpen}
        handleCloseCreateCollectionModal={handleCloseCreateCollectionModal}
      />
    </S.PageWrapper>
  )
}
