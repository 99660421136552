import { useIsActionPermitted } from 'hooks/useIsActionPermitted'
import { AccessActions } from 'types/permissions/enums'
import { AppDTO } from 'types/products/product'

export enum InvitedUserType {
  GUEST = 'GUEST',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  DEVELOPER = 'DEVELOPER',
  PRODUCT_OWNER = 'PRODUCT_OWNER',
}

interface Props {
  product?: AppDTO
  accessActions?: AccessActions[]
}

export const useGetInvitedUserType = ({ product, accessActions }: Props = {}): InvitedUserType => {
  const { isActionPermitted } = useIsActionPermitted({ product, accessAction: accessActions })
  const hasProductManage = isActionPermitted([AccessActions.PRODUCT_MANAGE])
  const hasVersionManage = isActionPermitted([AccessActions.VERSION_MANAGE])

  const isContentManager = hasProductManage && !hasVersionManage
  const isDeveloper = hasVersionManage && !hasProductManage
  const isProductOwner = hasVersionManage && hasProductManage

  if (isProductOwner) return InvitedUserType.PRODUCT_OWNER
  else if (isContentManager) return InvitedUserType.CONTENT_MANAGER
  else if (isDeveloper) return InvitedUserType.DEVELOPER
  else return InvitedUserType.GUEST
}
