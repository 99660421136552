import { ReactElement, ReactNode } from 'react'
import { Route, useLocation, Routes, Navigate } from 'react-router-dom'

interface Props<RouteMap extends Record<string, () => ReactNode>> {
  defaultRoute: keyof RouteMap
  routeMap: RouteMap
}

/**
 * Renders one of the mapped values based on location hash.
 * Works well with {@link Tabs} and {@link NavTab}.
 */
export const HashRoute = <RouteMap extends Record<string, () => ReactElement>>({
  defaultRoute,
  routeMap,
}: Props<RouteMap>) => {
  const { hash, pathname, state } = useLocation()

  const hashName = hash.slice(1)
  const cantRender = (defaultRoute && !hash) || !routeMap[hashName]

  return (
    <Routes>
      <Route
        path=""
        element={
          cantRender ? (
            <Navigate replace to={`${pathname}#${defaultRoute.toString()}`} state={state} />
          ) : (
            routeMap[hashName]()
          )
        }
      />
    </Routes>
  )
}
