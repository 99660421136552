import { ListItemChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppListItemCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { CollectionDetailsTabs } from 'pages/collections/details/CollectionDetailsPage'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/collections/details/components/sidebarMenu/SidebarMenu.styled'

export type CollectionDetailsTabType = keyof typeof CollectionDetailsTabs
export const collectionDetailsTabIds = ['PRODUCTS'] as CollectionDetailsTabType[]

export const SidebarMenu = () => {
  const { t } = useTranslation(['collections'])
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { collectionId } = useParams()
  const activeTab = (hash.slice(1) as CollectionDetailsTabs) || CollectionDetailsTabs.PRODUCTS

  const tabs = useMemo(
    () =>
      collectionDetailsTabIds.map(tabId => ({
        label: t(
          `collections|collection_details.sidebar_menu.${
            collectionDetailsTabIds[0].toLowerCase() as Lowercase<CollectionDetailsTabType>
          }`,
        ),
        isActive: activeTab === tabId,
        id: tabId,
      })),
    [t, activeTab],
  )

  const handleItemClick = ({ detail }: WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    const tabId = detail.value as CollectionDetailsTabType
    navigate({
      pathname: RoutesManager.collections.details.getURL({ collectionId: collectionId! }),
      hash: tabId,
    })
  }

  return (
    <S.SidebarMenu gap={6} data-testid="sidebar-menu">
      {tabs.map(({ id, isActive, label }) => (
        <S.MenuItem
          key={id}
          value={id}
          checked={isActive}
          onWppChangeListItem={handleItemClick}
          data-testid={`${id}-sidebar-menu-item`}
        >
          <WppTypography type={isActive ? 's-midi' : 's-body'} slot="label">
            {label}
          </WppTypography>
        </S.MenuItem>
      ))}
    </S.SidebarMenu>
  )
}
