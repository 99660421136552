import styled from '@emotion/styled'
import { WppCard } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const ProductsCard = styled(WppCard)`
  height: 100%;
  width: 100%;
  min-height: 300px;

  &::part(card) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ag-root.ag-unselectable {
    user-select: auto;
  }

  .ag-overlay-wrapper {
    pointer-events: auto;
  }
`

export const CardBody = styled(Flex)`
  width: 100%;
`
