import styled from '@emotion/styled'
import { WppActionButton, WppIconClose } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'

export const ContentWrapper = styled(Flex)`
  width: 400px;
  max-height: 360px;
  padding: 8px 16px;
`

export const Header = styled(Flex)`
  padding: 4px 0;
  margin-bottom: 8px;
`

export const VersionsList = styled(Flex)`
  height: 100%;
  overflow-y: auto;
  margin-bottom: 8px;
`

export const VersionDescription = styled(Truncate)`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
  margin-left: 28px;
`

export const IconClose = styled(WppIconClose)`
  pointer-events: none;
`

export const SelecteVersionButton = styled(WppActionButton)`
  pointer-events: all;
`
