import { styled } from '@mui/material/styles'
import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'
import { EmptyFolderSvg } from 'ui-base/svgIcons/emptyState/assets/EmptyFolderSvg'

export const Root = styled(Flex)<{ margin?: string }>`
  margin: ${({ margin }) => (margin ? margin : 'auto')};
`

export const EmptyFolder = styled(EmptyFolderSvg)`
  width: 100px;
  height: 100px;
`

export const SubtitleWrapper = styled('div')`
  text-align: center;
`

export const Subtitle = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`

export const SubtitleLink = styled('a')`
  color: var(--wpp-grey-color-800);
  border-bottom: 1px solid var(--wpp-grey-color-800);
  margin-left: 3px;
`
