import { TFunction } from 'i18next'
import * as Yup from 'yup'

import {
  CollectionDTO,
  CollectionProductDTO,
  CollectionProductWithVersionsDTO,
  CollectionsShortVersionDTO,
} from 'types/collections/collection'
import { MayBeNull } from 'types/common/utils'

export type CollectionFormShortVersionDTO = CollectionsShortVersionDTO & {
  isSelected: boolean
}

export type CollectionFormProductWithVersionsDTO = Omit<CollectionProductWithVersionsDTO, 'versions'> & {
  hasSelectedVersions: boolean
  versions: CollectionFormShortVersionDTO[]
}

export type CollectionFormDTO = Pick<CollectionDTO, 'name'> & {
  shortDescription: MayBeNull<string>
  elements: {
    productId: string
    versionId: string
  }[]
}

export type CollectionFormElementValue = Pick<
  CollectionProductDTO,
  | 'productId'
  | 'productName'
  | 'productType'
  | 'categoryLogoUrl'
  | 'logoMeta'
  | 'shortDescription'
  | 'versionId'
  | 'versionName'
> & {
  isSelected: boolean
}

export type CollectionFormValues = Pick<CollectionDTO, 'name' | 'shortDescription'> & {
  elements: CollectionFormElementValue[]
}

export const getCollectionInitialValues = (collection?: CollectionDTO): CollectionFormValues => {
  return {
    name: collection?.name || '',
    shortDescription: collection?.shortDescription || '',
    elements: collection?.elements.map(element => ({ ...element, isSelected: true })) || [],
  }
}

export const mapCollectionValues = (values: CollectionFormValues): CollectionFormDTO => {
  const { name, shortDescription, elements } = values

  const mappedElements = elements.map(({ productId, versionId }) => ({
    productId,
    versionId,
  }))

  return {
    name,
    shortDescription: shortDescription || null,
    elements: mappedElements,
  }
}

export const COLLECTION_NAME_FIELD_MAX_CHARS = 120
export const COLLECTION_SHORT_DESCRIPTION_MAX_CHARS = 256

export const getCollectionFormValidationSchema = ({
  t,
  isUpdate = false,
}: {
  t: TFunction<['errors']>
  isUpdate?: boolean
}) =>
  Yup.object({
    name: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        COLLECTION_NAME_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: COLLECTION_NAME_FIELD_MAX_CHARS,
        }),
      ),
    shortDescription: Yup.string()
      .trim()
      .max(
        COLLECTION_SHORT_DESCRIPTION_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: COLLECTION_SHORT_DESCRIPTION_MAX_CHARS,
        }),
      ),
    elements: isUpdate
      ? Yup.array()
      : Yup.array().required(t('errors|form_validation.required')).min(1, t('errors|form_validation.required')),
  })
