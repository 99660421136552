import { WppButton, WppSideModal } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { ProductSelectionStep } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/ProductsSelectionStep'
import { useUpsertCollectionForm } from 'pages/collections/common/createCollectionSideModal/hooks/useUpsertCollectionForm'
import { UPSERT_COLLECTION_STEPS } from 'pages/collections/common/createCollectionSideModal/types'
import { Flex } from 'ui-base/flex/Flex'

interface Props {
  isUpdateCollectionModalOpen: boolean
  handleCloseUpdateCollectionModal: () => void
  onCollectionUpdateSuccess?: () => void
}

export interface CreateCollectionSideModalState {
  search: string
  shared: boolean
}

const initialSideModalState: CreateCollectionSideModalState = {
  search: '',
  shared: false,
}

export const UpdateCollectionSideModal = ({
  isUpdateCollectionModalOpen,
  handleCloseUpdateCollectionModal,
  onCollectionUpdateSuccess,
}: Props) => {
  const { t } = useTranslation(['collections', 'common', 'errors'])
  const [{ search, shared }, setState] = useSetState<CreateCollectionSideModalState>({
    search: '',
    shared: false,
  })
  const { form, onSubmit, reset, errors, isSubmitting } = useUpsertCollectionForm({
    isUpdate: true,
    onCollectionUpsertSuccess: onCollectionUpdateSuccess,
    handleCloseSideModal: handleCloseUpdateCollectionModal,
  })

  const isValid = !Object.values(errors).length
  const isSaveButtonDisabled = !isValid

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <WppSideModal
          open={isUpdateCollectionModalOpen}
          onWppSideModalClose={handleCloseUpdateCollectionModal}
          onWppSideModalCloseComplete={() => {
            reset()
            setState(initialSideModalState)
          }}
          disableOutsideClick
          size="l"
          data-testid="update-collection-side-modal"
        >
          <h3 slot="header" data-testid="update-collection-side-modal-title">
            {t('collections|update_collection.title')}
          </h3>
          <Flex slot="body">
            <ProductSelectionStep
              shared={shared}
              search={search}
              currentStep={UPSERT_COLLECTION_STEPS.PRODUCTS}
              isCollectionModalOpen={isUpdateCollectionModalOpen}
              setState={(props: Partial<CreateCollectionSideModalState>) => setState(props)}
            />
          </Flex>

          <Flex justify="between" slot="actions">
            <Flex justify="flex-end" grow={1} gap={12}>
              <WppButton
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleCloseUpdateCollectionModal}
                data-testid="cancel-collection-update-button"
              >
                {t('common|cancel')}
              </WppButton>
              <WppButton
                loading={isSubmitting}
                disabled={isSaveButtonDisabled}
                onClick={onSubmit}
                data-testid="submit-collection-update-button"
              >
                {t('common|save')}
              </WppButton>
            </Flex>
          </Flex>
        </WppSideModal>
      </form>
    </FormProvider>
  )
}
