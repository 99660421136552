import { useParams } from 'react-router-dom'

import { useCollectionApi } from 'api/collections/queries/useCollectionApi'

export const useCollectionData = () => {
  const { collectionId } = useParams()
  const {
    data: collection,
    isLoading: isCollectionLoading,
    isError,
    error,
  } = useCollectionApi({
    params: { collectionId: collectionId! },
    enabled: !!collectionId,
    staleTime: 180000, // 3min
  })

  return {
    collection: collection!,
    isCollectionLoading,
    isCollectionError: isError,
    collectionError: error,
  }
}
