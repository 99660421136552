export enum PageSizes {
  PAGINATED_MY_PRODUCTS_SECTION_SIZE = 12,
  PAGINATED_MY_PRODUCTS_TABLE_VIEW_SIZE = 36,
  PAGINATED_ALL_OS_APPS = 25,
  PAGINATED_RELATED_PRODUCTS_SIZE = 20,
  PAGINATED_APP_VERSIONS_SIZE = 10,
  PAGINATED_APP_PERMISSIONS_SIZE = 10,
  PAGINATED_APP_PROFILES_SIZE = 10,
  PAGINATED_APP_ROLES_SIZE = 10,
  PAGINATED_TENANTS = 15,
  PAGINATED_LOAD_ALL = 10000,
  PAGINATED_PRODUCTS_WITH_VERSIONS = 20,
  PAGINATED_COLLECTIONS_SECTION_SIZE = 12,
}
