import { WppTypography } from '@platform-ui-kit/components-library-react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import * as S from 'ui-shared/collectionCard/CollectionCard.styled'

interface Props {
  updatedAt: string
}

export const CollectionCardFooter = ({ updatedAt }: Props) => {
  const { t } = useTranslation(['common'])
  return (
    <S.CollectionCardFooter data-testid="collection-card-footer">
      <WppTypography type="xs-body">{t('common|updated')}</WppTypography>
      <S.Timestamp type="xs-strong" data-testid="collection-creation-time">
        {dayjs(updatedAt).format('lll')}
      </S.Timestamp>
    </S.CollectionCardFooter>
  )
}
