import { FetchQueryOptions, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { FetcherParameters, FetcherResponse, QueryFetcher } from 'api/common/types'
import { useStableCallback } from 'hooks/useStableCallback'

export function createUseFetch<F extends QueryFetcher, P = FetcherParameters<F>, R = FetcherResponse<F>>({
  queryKey,
  fetcher,
}: {
  queryKey: string
  fetcher: F
}) {
  return function useFetch(
    options: Omit<FetchQueryOptions<R, AxiosError>, 'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn'> = {},
  ) {
    const queryClient = useQueryClient()

    return useStableCallback((params: P = {} as P) =>
      queryClient.fetchQuery<R, AxiosError>([queryKey, params], ({ signal }) => fetcher(params)(signal) as R, options),
    )
  }
}
