export enum UpsertCollectionErrorCode {
  DUPLICATE_NAME_ON_SAME_TENANT = 'DEVHUB_COLLECTION:DUPLICATE_COLLECTION_NAME_ON_SAME_WORKSPACE',
  DUPLICATE_NAME_ON_OTHER_TENANTS = 'DEVHUB_COLLECTION:DUPLICATE_COLLECTION_NAME_ON_OTHER_WORKSPACES',
}

export enum UPSERT_COLLECTION_STEPS {
  COLLECTION_INFO = 1,
  PRODUCTS = 2,
}

export interface UpsertCollectionSideModalState {
  search: string
  shared: boolean
  currentStep?: UPSERT_COLLECTION_STEPS
}
