import { styled } from '@mui/material/styles'

import { Flex } from 'ui-base/flex/Flex'

export const TableBody = styled(Flex)`
  flex: 1;

  .ag-root.ag-layout-auto-height {
    overflow: visible;
  }

  .ag-center-cols-container,
  .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: unset;
  }

  .ag-header-cell:not(.ag-header-cell-sortable) {
    --ag-header-cell-hover-background-color: transparent;
  }

  .ag-header-cell-sortable {
    cursor: pointer;
  }

  .ag-header-cell:after {
    content: unset;
  }
  .ag-selection-checkbox {
    opacity: 1 !important;
  }
  .ag-cell-wrapper {
    display: block;
    max-width: 100%;
    overflow: hidden;
  }
  .ag-cell.no-cell-overflow-hidden .ag-cell-wrapper {
    overflow: visible !important;
  }

  .ag-row {
    z-index: 0;
    &.ag-row-hover {
      z-index: 1;
    }
    &.ag-row-focus {
      z-index: 2;
    }
  }

  .ag-cell {
    display: flex;
    align-items: center;
    text-overflow: ellipsis !important;
    overflow-x: visible !important;
    line-height: unset !important;
  }

  .ag-cell-value {
    overflow: visible;
    overflow-x: unset;
  }

  .ag-root-wrapper,
  .ag-layout-auto-height,
  .ag-center-cols-viewport,
  .ag-center-cols-clipper {
    overflow: visible;
  }
`
