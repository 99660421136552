import { AvatarSize } from '@platform-ui-kit/components-library'
import { WppAvatar } from '@platform-ui-kit/components-library-react'

import { useAppLogoUrl } from 'hooks/useAppLogoUrl'
import { ImageMetaDTO } from 'types/common/attachments'
import { MayBeNull } from 'types/common/utils'
import { ProductType } from 'types/products/enums'

interface Props {
  productType: ProductType
  logoMeta: MayBeNull<ImageMetaDTO>
  categoryLogoUrl?: string
  customDefaultLogoUrl?: string
  size?: AvatarSize
}

export const ProductLogoAvatar = ({
  productType,
  logoMeta,
  categoryLogoUrl,
  customDefaultLogoUrl,
  size = 'xs',
  ...rest
}: Props) => {
  const logoUrl = useAppLogoUrl({
    productType,
    logoMeta: logoMeta!,
    categoryLogoUrl,
    customDefaultLogoUrl,
  })

  return <WppAvatar src={logoUrl} size={size} variant="square" {...rest} />
}
