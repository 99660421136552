import { NavigationState } from '@platform-ui-kit/components-library'
import { WppGrid } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { Leaves } from 'types/common/utils'
import { CommonDictionaryTypes } from 'types/i18next'
import { AppPermissions } from 'types/permissions/enums'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'layout/header/Header.styled'

interface HeaderLinkConfig extends NavigationState {
  label: Leaves<CommonDictionaryTypes>
  isExternalLink?: boolean
  permission?: AppPermissions
}

export const Header = () => {
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const { isPermitted } = useIsPermitted()
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const {
    osContext: { baseUrl },
  } = useOs()

  const headerLinks: HeaderLinkConfig[] = useMemo(
    () => [
      {
        label: 'overview',
        value: '/overview',
        path: RoutesManager.overview.root.getURL(),
      },
      {
        label: 'products',
        value: '/products',
        path: RoutesManager.myProducts.root.getURL(),
      },
      {
        label: 'product_support',
        value: '/admin',
        path: RoutesManager.admin.root.getURL(),
        permission: AppPermissions.WPP_OPEN_DEVHUB_ALL_TENANTS_OPEN_PRODUCTS_VIEW,
      },
    ],
    [],
  )

  const navLinks: NavigationState[] = useMemo(
    () =>
      headerLinks
        .filter(({ permission }) => !permission || isPermitted(permission))
        .map(({ label, value, path, ...rest }) => ({
          ...rest,
          value,
          label: t(label),
          path: rest.isExternalLink ? path : `/${baseUrl}${path}`,
        })),
    [headerLinks, baseUrl, t, isPermitted],
  )

  const handleNavigate = useCallback(
    (event: CustomEvent) => {
      const { path, value } = event.detail
      const relativePath = path.replace(`/${baseUrl}`, '')
      const isExternalLink = headerLinks.find(activeLink => activeLink.value === value)?.isExternalLink
      isExternalLink ? window.open(path) : navigate(relativePath)
    },
    [headerLinks, navigate, baseUrl],
  )

  const activeRootPath = pathname.match(/^\/([^/]+)/)?.[0]
  const isLoading = !isAuthenticated

  return (
    <S.TopBarWrapper align="center">
      <S.Wrapper>
        <WppGrid container>
          <S.GridItem item all={24}>
            {!isLoading && (
              <S.TopBar
                navigation={navLinks}
                value={activeRootPath}
                onWppChange={handleNavigate}
                data-testid="header-topbar"
              >
                <div slot="app">
                  <S.AppName type="m-strong">{t('common|dev_hub')}</S.AppName>
                </div>
              </S.TopBar>
            )}
          </S.GridItem>
        </WppGrid>
      </S.Wrapper>
    </S.TopBarWrapper>
  )
}
