import { WppDivider, WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRowSkeleton/ProductRowSkeleton.styled'

export const ProductRowSkeleton = () => (
  <Flex direction="column">
    <S.Wrapper gap={8} align="center">
      <WppSkeleton width={32} height={32} variant="circle" />
      <WppSkeleton width={535} height={32} />
      <WppSkeleton width={112} height={32} />
    </S.Wrapper>
    <WppDivider />
  </Flex>
)
