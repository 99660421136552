import { fetchInfiniteCollections } from 'api/collections/fetchers/fetchInfiniteCollections'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteCollectionsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.COLLECTIONS,
  fetcher: fetchInfiniteCollections,
  selector: res => res?.pages?.flatMap(page => page.data?.content) || [],
  getNextPageParam: lastPage => (lastPage?.data?.last ? undefined : lastPage?.data?.number + 1),
})
