import { styled } from '@mui/material/styles'
import { WppSkeleton } from '@platform-ui-kit/components-library-react'

export const TableWrapper = styled('div', {
  shouldForwardProp: props => props !== 'isLoading',
})<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  --ag-header-cell-hover-background-color: var(--wpp-grey-color-000);
  .ag-header-cell:after {
    content: unset;
  }
  height: 100%;
`

export const Skeleton = styled(WppSkeleton, {
  shouldForwardProp: props => props !== 'actualWidth' && props !== 'rowHeight',
})<{ actualWidth: number; rowHeight: number }>`
  width: ${({ actualWidth }) => `${actualWidth * 0.7}px`};
  max-width: 100%;
  height: ${({ rowHeight }) => `${rowHeight * 0.45}px`};
`
