import { WppToggle } from '@platform-ui-kit/components-library-react'
import { GetRowIdParams } from 'ag-grid-community'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ActivateCollectionTenantParams } from 'api/collections/fetchers/activateCollectionTenant'
import { TableEmptyState } from 'pages/collections/details/tabs/availability/components/availabilityTable/components/TableEmptyState'
import { ProductTenant, TenantStatus } from 'types/products/tenant'
import { ColumnDefs, DataTable, DataTableProps } from 'ui-base/dataTable/DataTable'
import { Truncate } from 'ui-base/truncate/Truncate'
import { handleApiErrors } from 'utils/api'

import * as S from 'pages/collections/details/tabs/availability/components/availabilityTable/AvailabilityTable.styled'

export interface AvailabilityTableProps extends DataTableProps {
  tenants: ProductTenant[]
  isLoading: boolean
  isError: boolean
  error: unknown
  isPreviousData: boolean
  isGloballyAvailable: boolean
  isAvailabilityDisabled?: boolean
  isCurrentTenant?: boolean
  isSearchApplied?: boolean
  activateTenantParams: ActivateCollectionTenantParams
  isActivatingTenant: boolean
  isFetchingNextPage?: boolean
  updateControlTenant: (activeTenantId: ProductTenant['tenantId']) => void
  activateSingleTenantAvailability: (tenantId: ProductTenant['tenantId']) => void
  resetSearch?: () => void
  //TODO: remove "optional" during deactivation implementation
  handleOpenDeactivationModal?: () => void
}

export const AvailabilityTable = ({
  tenants,
  isLoading,
  isError,
  error,
  isPreviousData,
  isGloballyAvailable,
  isAvailabilityDisabled,
  isCurrentTenant,
  isSearchApplied,
  isActivatingTenant,
  activateTenantParams,
  resetSearch,
  updateControlTenant,
  handleOpenDeactivationModal,
  activateSingleTenantAvailability,
  ...rest
}: AvailabilityTableProps) => {
  const { t } = useTranslation(['collections', 'versions'])

  const getRowId = useCallback(({ data }: GetRowIdParams<ProductTenant>) => data.tenantId, [])

  const columnDefs = useMemo<ColumnDefs<ProductTenant>[]>(() => {
    const handleTenantActions = ({
      isActivation,
      activeTenantId,
    }: {
      isActivation: boolean
      activeTenantId: ProductTenant['tenantId']
    }) => {
      updateControlTenant(activeTenantId)

      isActivation ? activateSingleTenantAvailability(activeTenantId) : handleOpenDeactivationModal?.()
    }

    return [
      {
        headerName: t(
          `collections|availability.table.column_names.${isCurrentTenant ? 'current_workspace' : 'other_workspaces'}`,
        ),
        cellRendererParams: ({ data }) => ({
          cell: (
            <S.StyledTooltip show showOnOverflow text={data?.tenantName}>
              <Truncate type="s-body">{data?.tenantName}</Truncate>
            </S.StyledTooltip>
          ),
        }),
        colId: 'tenantName',
        flex: 0.32,
      },
      {
        headerName: t('collections|availability.table.column_names.description'),
        cellRendererParams: ({ data }) => ({
          isEmptyValue: !data?.tenantDescription,
          cell: (
            <S.StyledTooltip show showOnOverflow text={data?.tenantDescription}>
              <Truncate type="s-body">{data?.tenantDescription}</Truncate>
            </S.StyledTooltip>
          ),
        }),
        colId: 'tenantDescription',
        flex: 0.64,
      },
      {
        headerName: t('collections|availability.table.column_names.status'),
        cellRendererParams: ({ data }) => ({
          cell: (
            <WppToggle
              checked={data?.access === TenantStatus.ACTIVE}
              onWppChange={() => {
                return isActivatingTenant && activateTenantParams.tenantId === data?.tenantId
                  ? null
                  : handleTenantActions({
                      isActivation: data?.access === TenantStatus.INACTIVE,
                      activeTenantId: data?.tenantId,
                    })
              }}
              //TODO: remove 'data?.access === TenantStatus.ACTIVE' during deactivation implementation
              disabled={isAvailabilityDisabled || isGloballyAvailable || data?.access === TenantStatus.ACTIVE}
              required
              data-testid="tenant-availability-toggle"
            />
          ),
        }),
        colId: 'tenantAvailabilityToggle',
        flex: 0.05,
        minWidth: 80,
        maxWidth: 80,
      },
    ]
  }, [
    isGloballyAvailable,
    isAvailabilityDisabled,
    isCurrentTenant,
    isActivatingTenant,
    activateTenantParams,
    t,
    activateSingleTenantAvailability,
    updateControlTenant,
    handleOpenDeactivationModal,
  ])

  if (!isLoading && error) {
    return handleApiErrors({ error, styles: { margin: '100px 0' }, fullHeight: false })
  }

  return (
    <DataTable
      rowData={tenants}
      columnDefs={columnDefs}
      isLoading={isLoading}
      emptyState={<TableEmptyState isSearchApplied={isSearchApplied!} handleClickResetButton={resetSearch!} />}
      isPreviousData={isPreviousData}
      getRowId={getRowId}
      {...rest}
    />
  )
}
