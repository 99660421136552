import { useCallback, useState } from 'react'

export interface DialogData {
  open: boolean
  handleClose: () => void
  handleOpen: () => void
}

export const useCustomDialog = (): DialogData => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => setIsOpen(false), [])
  const handleOpen = useCallback(() => setIsOpen(true), [])

  return {
    open: isOpen,
    handleClose,
    handleOpen,
  }
}
