import { WppActionButton, WppButton, WppIconArrow, WppSideModal } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAsyncFn, useSetState } from 'react-use'

import { useFetchCollectionValidationApi } from 'api/collections/queries/useValidateCollectionApi'
import { useToast } from 'hooks/useToast'
import { CollectionInfoStep } from 'pages/collections/common/createCollectionSideModal/components/collectionInfoStep/CollectionInfoStep'
import { ProductSelectionStep } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/ProductsSelectionStep'
import { Stepper } from 'pages/collections/common/createCollectionSideModal/components/stepper/Stepper'
import { useUpsertCollectionForm } from 'pages/collections/common/createCollectionSideModal/hooks/useUpsertCollectionForm'
import {
  UPSERT_COLLECTION_STEPS,
  UpsertCollectionSideModalState,
  UpsertCollectionErrorCode,
} from 'pages/collections/common/createCollectionSideModal/types'
import { Flex } from 'ui-base/flex/Flex'
import { unpackApiError } from 'utils/form'

interface Props {
  isCreateCollectionModalOpen: boolean
  handleCloseCreateCollectionModal: () => void
}

const initialSideModalState: UpsertCollectionSideModalState = {
  search: '',
  shared: false,
  currentStep: UPSERT_COLLECTION_STEPS.COLLECTION_INFO,
}

export const CreateCollectionSideModal = ({ isCreateCollectionModalOpen, handleCloseCreateCollectionModal }: Props) => {
  const { t } = useTranslation(['products', 'collections', 'common', 'errors'])
  const { showToast } = useToast()
  const [{ currentStep, search, shared }, setState] = useSetState<UpsertCollectionSideModalState>({
    search: '',
    shared: false,
    currentStep: UPSERT_COLLECTION_STEPS.COLLECTION_INFO,
  })

  const { form, onSubmit, reset, watch, setError, errors, isSubmitting } = useUpsertCollectionForm({
    handleCloseSideModal: handleCloseCreateCollectionModal,
  })

  const collectionName = watch('name')
  const elements = watch('elements')

  const handleFetchCollectionValidation = useFetchCollectionValidationApi()

  const [{ loading: isValidatingCollectionName }, validateCollectionName] = useAsyncFn(async () => {
    try {
      await handleFetchCollectionValidation({ name: collectionName })
    } catch (apiError) {
      const { status, code } = unpackApiError(apiError)

      if (status === 409) {
        if (code === UpsertCollectionErrorCode.DUPLICATE_NAME_ON_SAME_TENANT)
          setError('name', {
            type: 'duplicate-collection',
            message: t('collections|create_collection.errors.collection_duplicate_on_the_tenant'),
          })
        if (code === UpsertCollectionErrorCode.DUPLICATE_NAME_ON_OTHER_TENANTS)
          setError('name', {
            type: 'duplicate-collection',
            message: t('collections|create_collection.errors.collection_duplicate_on_the_os'),
          })
      } else {
        showToast({ message: t('errors|general'), type: 'error' })
      }
    }
  }, [collectionName])

  const isValid = !Object.values(errors).length && !!collectionName
  const elementsCount = elements.length
  const isSaveButtonDisabled = !isValid || !elementsCount

  const currentStepContent = useMemo(() => {
    return {
      [UPSERT_COLLECTION_STEPS.COLLECTION_INFO]: (
        <CollectionInfoStep collectionName={collectionName} validateCollectionName={validateCollectionName} />
      ),
      [UPSERT_COLLECTION_STEPS.PRODUCTS]: (
        <ProductSelectionStep
          shared={shared}
          search={search}
          currentStep={currentStep!}
          isCollectionModalOpen={isCreateCollectionModalOpen}
          setState={(props: Partial<UpsertCollectionSideModalState>) => setState(props)}
        />
      ),
    }[currentStep!]
  }, [currentStep, collectionName, shared, search, isCreateCollectionModalOpen, setState, validateCollectionName])

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <WppSideModal
          open={isCreateCollectionModalOpen}
          onWppSideModalClose={handleCloseCreateCollectionModal}
          onWppSideModalCloseComplete={() => {
            reset()
            setState(initialSideModalState)
          }}
          disableOutsideClick
          size="l"
          data-testid="create-collection-side-modal"
        >
          <h3 slot="header" data-testid="create-collection-side-modal-title">
            {t('collections|create_collection.title')}
          </h3>
          <Flex slot="body" direction="column">
            <Stepper
              errors={errors}
              collectionName={collectionName}
              currentStep={currentStep!}
              setState={setState}
              elementsCount={elementsCount}
            />

            {currentStepContent}
          </Flex>

          <Flex justify="between" slot="actions">
            {currentStep !== UPSERT_COLLECTION_STEPS.COLLECTION_INFO && (
              <WppActionButton
                onClick={() => setState(prev => ({ currentStep: prev.currentStep! - 1 }))}
                data-testid="previous-step-button"
              >
                <WppIconArrow direction="left" slot="icon-start" />
                {t('common|previous')}
              </WppActionButton>
            )}

            <Flex justify="flex-end" grow={1} gap={12}>
              <WppButton
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleCloseCreateCollectionModal}
                data-testid="cancel-collection-creation-button"
              >
                {t('common|cancel')}
              </WppButton>
              {currentStep !== UPSERT_COLLECTION_STEPS.PRODUCTS ? (
                <WppButton
                  disabled={!isValid}
                  loading={isValidatingCollectionName}
                  onClick={() => setState(prev => ({ currentStep: prev.currentStep! + 1 }))}
                  data-testid="next-step-button"
                >
                  {t('common|next')}
                </WppButton>
              ) : (
                <WppButton
                  loading={isSubmitting}
                  disabled={isSaveButtonDisabled}
                  onClick={onSubmit}
                  data-testid="submit-collection-creation-button"
                >
                  {t('common|save')}
                </WppButton>
              )}
            </Flex>
          </Flex>
        </WppSideModal>
      </form>
    </FormProvider>
  )
}
