import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import {
  WppTypography,
  WppCard,
  WppMenuContext as CustomWppMenuContext,
  WppTag,
  WppDivider,
} from '@platform-ui-kit/components-library-react'
import { Link as CustomLink } from 'react-router-dom'

import { Flex } from 'ui-base/flex/Flex'
import { Tooltip as CustomTooltip } from 'ui-base/tooltip/Tooltip'
import { Truncate } from 'ui-base/truncate/Truncate'

const typographyStyle = css`
  color: var(--wpp-grey-color-800);
`
const SLOT_ACTIONS_WIDTH = 50

export const Link = styled(CustomLink)`
  text-decoration: none;
`

export const ProductCard = styled(WppCard)`
  &::part(card) {
    display: flex;
    flex-direction: column;
    min-height: 146px;
    overflow: visible;
  }

  &::part(header-wrapper) {
    height: 28px;
    margin-bottom: 24px;
  }
`

export const ProductNameWrapper = styled(Flex)`
  overflow: hidden;
  margin-right: ${SLOT_ACTIONS_WIDTH}px;
`

export const ProductName = styled(Truncate)`
  color: var(--wpp-grey-color-1000);
  overflow: hidden;

  &::part(typography) {
    display: block;
  }
`

export const Timestamp = styled(WppTypography)`
  flex-shrink: 0;
  ${typographyStyle};
`

export const WppMenuContext = styled(CustomWppMenuContext)`
  position: relative;
`

export const ProductCardContent = styled('div')`
  flex-grow: 1;
`

export const ProductDescription = styled(Truncate)`
  ${typographyStyle};
  height: 22px;

  &::part(typography) {
    display: block;
  }
`

export const ProductCardFooter = styled('footer')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`

export const ProductStatusTag = styled(WppTag, {
  shouldForwardProp: props => props !== 'background' && props !== 'color',
})<{
  background: string
  color: string
}>`
  vertical-align: middle;
  background-color: ${props => props.background};

  &::part(label) {
    color: ${props => props.color};
  }
`

export const Divider = styled(WppDivider)`
  width: 1px;
  height: 24px;
  background: var(--wpp-grey-color-300);
`

export const ButtonsGroup = styled(Flex)`
  width: 100%;
`

export const FooterTags = styled(Flex)`
  flex-wrap: wrap;
`

export const DeleteModalBody = styled('p')`
  word-brear: break-word;
`

export const DeleteModalHeader = styled(Flex)`
  word-break: break-word;
`

export const Tooltip = styled(CustomTooltip)`
  display: flex;
`
