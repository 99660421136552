import {
  WppActionButton,
  WppDivider,
  WppIconInfo,
  WppIconPlus,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { defaultTooltipConfig } from 'hooks/form/useCommonLabelProps'
import { useAppLogoUrl } from 'hooks/useAppLogoUrl'
import { InvitedUserType, useGetInvitedUserType } from 'hooks/useGetInvitedUserType'
import { SelectVersionPopover } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/selectVersionPopover/SelectVersionPopover'
import {
  CollectionFormProductWithVersionsDTO,
  CollectionFormElementValue,
} from 'pages/collections/common/createCollectionSideModal/formUtils'
import { ProductType } from 'types/products/enums'
import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'
import { capitalize } from 'utils/strings'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/ProductRow.styled'

interface Props {
  productWithVersions: CollectionFormProductWithVersionsDTO
  handleAddVersion: (element: CollectionFormElementValue) => void
  handleRemoveVersion: (versionId: string) => void
  handleVersionsSelection: (elements: CollectionFormElementValue[]) => void
}

export const ProductWithVersionsRow = ({
  productWithVersions,
  handleAddVersion,
  handleRemoveVersion,
  handleVersionsSelection,
}: Props) => {
  const { t } = useTranslation(['collections', 'common'])
  const {
    id: productId,
    productType,
    categoryLogoUrl,
    logoMeta,
    name: productName,
    shortDescription: productDescription,
    versions,
    accessActions,
    hasSelectedVersions,
  } = productWithVersions
  const invitedUserType = useGetInvitedUserType({ accessActions })
  const isProductOwner = invitedUserType === InvitedUserType.PRODUCT_OWNER
  const isNoCodeProduct = productType === ProductType.NO_CODE_APPLICATION
  const showNoVersionsTooltip = isProductOwner && !versions.length
  const logoUrl = useAppLogoUrl({
    productType,
    logoMeta: logoMeta!,
    categoryLogoUrl,
  })

  return (
    <>
      <S.ProductRowContent
        gap={8}
        align="center"
        justify="between"
        isHighlighted={hasSelectedVersions}
        data-testid="product-with-version-row"
      >
        <S.ProductLogo
          src={logoUrl}
          size="s"
          variant="square"
          isDisabled={!isProductOwner}
          data-testid="product-with-version-logo"
        />

        <S.NameWrapper direction="column" grow={1} isDisabled={!isProductOwner}>
          <Truncate type="s-body">{productName}</Truncate>
          <S.Description type="s-body" data-testid="product-with-version-description">
            {productDescription || '-'}
          </S.Description>
        </S.NameWrapper>

        <S.ActionButtonWrapper gap={8} align="center">
          {!isProductOwner && (
            <WppTooltip
              text={t('collections|create_collection.products_step.collaboration_role_warning', {
                role: capitalize(invitedUserType),
              })}
              config={defaultTooltipConfig}
              data-testid="ownership-tooltip"
            >
              <WppIconInfo />
            </WppTooltip>
          )}
          {isNoCodeProduct ? (
            <WppActionButton
              disabled={!isProductOwner}
              onClick={() =>
                hasSelectedVersions
                  ? handleRemoveVersion(versions![0].id)
                  : handleAddVersion({
                      productName,
                      productId: productId!,
                      productType,
                      versionId: versions![0].id,
                      versionName: versions![0].name,
                      shortDescription: productDescription,
                      categoryLogoUrl,
                      logoMeta,
                      isSelected: !hasSelectedVersions,
                    })
              }
              data-testid={`${hasSelectedVersions ? 'remove' : 'add'}-version-button`}
            >
              {hasSelectedVersions ? t('common|remove') : <WppIconPlus slot="icon-start" />}
            </WppActionButton>
          ) : (
            <Flex align="center" gap={8}>
              {showNoVersionsTooltip && (
                <WppTooltip
                  text={t('collections|create_collection.products_step.no_versions_tooltip')}
                  config={{ ...defaultTooltipConfig, placement: 'left' }}
                  data-testid="no-versions-tooltip"
                >
                  <WppIconInfo style={{ pointerEvents: 'none' }} />
                </WppTooltip>
              )}
              <SelectVersionPopover
                isProductOwner={isProductOwner}
                invitedUserType={invitedUserType}
                productWithVersions={productWithVersions}
                handleVersionsSelection={handleVersionsSelection}
              />
            </Flex>
          )}
        </S.ActionButtonWrapper>
      </S.ProductRowContent>

      <WppDivider />
    </>
  )
}
