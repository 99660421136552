import qs from 'qs'

import { devHubApi } from 'api'
import { ApiRequestPaginationParameters, ApiRequestSortParameters, Paginated } from 'types/api/api'
import { CollectionProductWithVersionsDTO } from 'types/collections/collection'

export interface ProductsWithVersionsFilters {
  searchPhrase?: string
  shared?: boolean
}

export type ProductsWithVersionsSortBy = Pick<CollectionProductWithVersionsDTO, 'updatedAt'>

type Params = ApiRequestPaginationParameters &
  ApiRequestSortParameters<ProductsWithVersionsSortBy, true> &
  ProductsWithVersionsFilters

export const fetchInfiniteProductsWithVersions = ({ page, size, sort, searchPhrase, shared = false }: Params) =>
  devHubApi.post<Paginated<CollectionProductWithVersionsDTO>>(
    '/collections/products',
    {},
    {
      params: {
        page,
        size,
        sort,
        shared,
        ...(!!searchPhrase && { searchPhrase }),
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  )
