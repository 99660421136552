import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  closestCenter,
  MeasuringStrategy,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SelectedProductRow } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/SelectedProductRow'
import { CollectionFormElementValue } from 'pages/collections/common/createCollectionSideModal/formUtils'
import { MayBeNull } from 'types/common/utils'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/selectedProductsSection/SelectedProductsSection.styled'

interface Props {
  selectedProducts: CollectionFormElementValue[]
  handleRemoveVersion: (versionId: string) => void
}

export const SelectedProductsSection = ({ selectedProducts, handleRemoveVersion }: Props) => {
  const { t } = useTranslation(['collections'])
  const { setValue } = useFormContext()

  const [activeProduct, setActiveProduct] = useState<MayBeNull<string>>(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const productIds = selectedProducts.map(({ versionId }) => versionId!)
  const getIndex = (key: string) => productIds.indexOf(key)
  const activeIndex = activeProduct ? getIndex(activeProduct) : -1

  const onSort = useCallback((products: CollectionFormElementValue[]) => setValue('elements', products), [setValue])

  return (
    <Flex direction="column" data-testid="selected-products-section">
      <S.SelectedProductsAccordion size="m" data-testid="selected-products-accordion">
        <WppTypography type="m-strong" slot="header">
          {t('collections|create_collection.products_step.products_selected', {
            count: selectedProducts.length,
          })}
        </WppTypography>
        <S.SelectedProductsWrapper direction="column" grow={1}>
          <DndContext
            modifiers={[restrictToParentElement]}
            sensors={sensors}
            collisionDetection={closestCenter}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
            onDragStart={({ active }) => {
              if (active) {
                setActiveProduct(active.id.toString())
              }
            }}
            onDragEnd={({ over }) => {
              setActiveProduct(null)

              if (over) {
                const overIndex = getIndex(over.id.toString())

                if (activeIndex !== overIndex) {
                  const sorted = arrayMove(selectedProducts, activeIndex, overIndex)

                  onSort(sorted)
                }
              }
            }}
          >
            <SortableContext items={productIds} strategy={rectSortingStrategy}>
              {!!selectedProducts.length ? (
                selectedProducts.map((product, index) => (
                  <SelectedProductRow
                    key={product.versionId}
                    element={product}
                    isLastRow={index === selectedProducts.length - 1}
                    handleRemoveVersion={handleRemoveVersion}
                    data-testid="selected-product-row"
                  />
                ))
              ) : (
                <S.NoSelectedProducts type="s-body" data-testid="no-selected-products">
                  {t('collections|create_collection.products_step.no_products_are_selected')}
                </S.NoSelectedProducts>
              )}
            </SortableContext>
          </DndContext>
        </S.SelectedProductsWrapper>
      </S.SelectedProductsAccordion>
    </Flex>
  )
}
