import { useQueryClient } from '@tanstack/react-query'

import { deleteCollectionApi } from 'api/collections/fetchers/deleteCollectionApi'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useDeleteCollectionApi = createUseMutation({ fetcher: deleteCollectionApi })

export const useDeleteCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useDeleteCollectionApi({
    onSuccess: () => {
      queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS])
    },
  })
}
