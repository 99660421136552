import { MayBeNull } from '@wpp-open/core'
import { useParams } from 'react-router-dom'

import { useProductApi } from 'api/products/queries/useProductApi'
import { NoCodeAppType, ProductType } from 'types/products/enums'
import { AppDTO } from 'types/products/product'

export const useAppData = <AppType extends AppDTO>() => {
  const { productId } = useParams()
  const {
    data: product,
    isLoading: isProductLoading,
    isError,
    error,
  } = useProductApi({
    params: { productId: productId! },
    enabled: !!productId,
    staleTime: 180000, // 3min
  })
  const isNoCodeApp = product?.productType === ProductType.NO_CODE_APPLICATION

  return {
    product: product as MayBeNull<AppType>,
    isNativeApp: product?.productType === ProductType.NATIVE_APPLICATION,
    isAiServiceApp: product?.productType === ProductType.AI_SERVICE_PRODUCT,
    isNoCodeApp,
    isExternalLinkApp: isNoCodeApp && product?.noCodeAppType! === NoCodeAppType.EXTERNAL_LINK,
    isEmbeddedLinkApp: isNoCodeApp && product?.noCodeAppType === NoCodeAppType.EMBEDDED_LINK,
    isEmbeddedCodeApp: isNoCodeApp && product?.noCodeAppType === NoCodeAppType.EMBEDDED_CODE,
    isMiroApp: isNoCodeApp && product?.noCodeAppType === NoCodeAppType.MIRO_BOARD,
    isProductLoading,
    isProductError: isError,
    productError: error,
  }
}
