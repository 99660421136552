import { useQueryClient } from '@tanstack/react-query'

import { updateCollectionApi } from 'api/collections/fetchers/updateCollectionApi'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useUpdateCollectionApi = createUseMutation({
  fetcher: updateCollectionApi,
})

export const useUpdateCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useUpdateCollectionApi({
    onSuccess: response => {
      queryClient.invalidateQueries([ApiQueryKeys.COLLECTION, { collectionId: response.data.id }])
      queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS])
    },
  })
}
