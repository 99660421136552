import { useCallback } from 'react'

import { useFetchCollection } from 'api/collections/queries/useCollectionApi'
import { TableInfiniteLoader } from 'ui-base/dataTable/InfiniteTable/utils'

interface Params {
  collectionId: string
}

export const useCollectionProductsLoader = ({ collectionId }: Params) => {
  const handleFetchCollectionElements = useFetchCollection({
    staleTime: 1000,
  })

  const loader: TableInfiniteLoader = useCallback(async () => {
    const {
      data: { elements },
    } = await handleFetchCollectionElements({
      collectionId,
    })

    return {
      data: elements,
      totalRowsCount: elements.length,
    }
  }, [collectionId, handleFetchCollectionElements])

  return { loader }
}
