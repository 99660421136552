export enum ApiQueryKeys {
  APP_VERSIONS = 'app/versions',
  APP_VERSION = 'app/version',
  APP_VERSION_PERMISSIONS = 'app/version-permissions',
  APP_VERSION_PERMISSION = 'app/version-permission',
  PAGE_BUILDER_APP_VERSION_CONFIG = 'app/version-page-builder-config',
  PAGE_BUILDER_TEMPLATES = 'templates',
  APP_PROFILE = 'app/profile',
  APP_PROFILES = 'app/profiles',
  APP_PROFILE_PERMISSIONS = 'app/profile-permissions',
  APP_PROFILE_PERMISSION = 'app/profile-permission',
  APP_VERSION_ROLES = 'app/version-roles',
  APP_VERSION_COMPLETION = 'app/version-completion',
  APP_PROFILE_ROLES = 'app/profile-roles',
  APP_VERSION_ROLE = 'app/version-role',
  APP_PROFILE_ROLE = 'app/profile-role',
  APP_DATA_TRANSFER_SETTINGS = 'app/data-transfer-settings',
  APP_AI_INTEGRATION = 'app/ai-integration',
  APP_COLLABORATION_ROLES = 'app/collaboration/roles',
  APP_COLLABORATION_ROLES_AND_USERS = 'app/collaboration/roles-and-users',
  COLLECTIONS = 'collections',
  COLLECTION = 'collection',
  COLLECTION_VALIDATE = 'collection/validate',
  PRODUCTS_WITH_VERSIONS = 'collections/products',
  ALL_OS_PRODUCTS = 'admin/all-os-products',
  PRODUCT = 'product',
  PRODUCTS_INFINITE = 'products/infinite',
  PRODUCT_CATEGORIES_CONFIG = 'product-categories-config',
  MARKETS = 'markets',
  VERSION_TENANT_AVAILABILITY = 'version-tenants/availability/product-tenant',
  PROFILE_TENANT_AVAILABILITY = 'profile-tenants/availability/product-tenant',
  VERSION_TENANTS_AVAILABILITY = 'version-tenants/availability',
  PROFILE_TENANTS_AVAILABILITY = 'profile-tenants/availability',
  TIP_CARDS = 'tip-cards',
  PAGE_BUIlDER_DOWNLOAD_URL = 'page-builder-download-url',
  DOWNLOAD_URLS = 'download-urls',
  DOWNLOAD_AGENCY_URLS = 'download-agency-urls',
  CURRENT_USER = 'current-user',
  USERS_LIST_INFINITE = 'users-list-infinite',
  USER_PRODUCT_REQUEST = 'user/product-requests',
  TENANT_HIERARCHY_TREE = 'tenant/hierarchy-tree',
  TENANT_NAVIGATION_TREE = 'tenant/navigation-tree',
  INSTANCES = 'app-instances',
}
