import { WppActionButton, WppButton, WppIconWarning, WppModal } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { useDeleteCollectionApiWithEffects } from 'api/collections/mutations/useDeleteCollectionApi'
import { useToast } from 'hooks/useToast'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'ui-shared/productCard/ProductCard.styled'

interface Props extends ComponentProps<typeof WppModal> {
  handleClose: () => void
  open: boolean
  collectionId: string
  collectionName: string
  redirectToCollectionsAfterDelete?: boolean
}

export const CollectionDeleteModal = ({
  handleClose,
  open,
  collectionId,
  collectionName,
  redirectToCollectionsAfterDelete = false,
  ...rest
}: Props) => {
  const { t } = useTranslation(['common', 'collections'])
  const { showToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: deleteCollection } = useDeleteCollectionApiWithEffects()

  const [{ loading: isDeleting }, handleDelete] = useAsyncFn(async () => {
    try {
      await deleteCollection({ id: collectionId })
      redirectToCollectionsAfterDelete && navigate(RoutesManager.collections.root.getURL())
      showToast({ message: t('collections|collection_delete_modal.notifications.success'), type: 'success' })
    } catch {
      showToast({ message: t('collections|collection_delete_modal.notifications.error'), type: 'error' })
    } finally {
      handleClose()
    }
  }, [collectionId])

  return (
    <WppModal
      disableOutsideClick
      onWppModalClose={handleClose}
      open={open}
      {...rest}
      data-testid="collection-delete-modal"
    >
      <S.DeleteModalHeader gap={8} align="center" slot="header" data-testid="collection-delete-modal-header">
        <WppIconWarning />
        {t('collections|collection_delete_modal.title', { name: collectionName })}
      </S.DeleteModalHeader>
      <S.DeleteModalBody slot="body" data-testid="collection-delete-modal-body">
        {t('collections|collection_delete_modal.body', { name: collectionName })}
      </S.DeleteModalBody>
      <S.ButtonsGroup justify="end" gap={6} align="center" slot="actions" data-testid="collection-delete-modal-actions">
        <WppActionButton variant="secondary" onClick={handleClose} data-testid="collection-delete-modal-cancel-button">
          {t('common|cancel')}
        </WppActionButton>
        <WppButton
          variant="destructive"
          size="s"
          onClick={handleDelete}
          loading={isDeleting}
          data-testid="collection-delete-modal-delete-button"
        >
          {t('common|delete')}
        </WppButton>
      </S.ButtonsGroup>
    </WppModal>
  )
}
