import { ReactElement, lazy } from 'react'

import { CollectionDetailsPage } from 'pages/collections/details/CollectionDetailsPage'
import { CollectionsPage } from 'pages/collections/list/CollectionsPage'
import { RoutesManager } from 'utils/routesManager'

interface AppRoute {
  Component: ReactElement
  path: string
}

const MyProductsPage = lazy(() =>
  import('pages/myProducts/MyProductsPage').then(({ MyProductsPage }) => ({
    default: MyProductsPage,
  })),
)

const ProductsPageV2 = lazy(() =>
  import('pages/productsV2/ProductsPageV2').then(({ ProductsPage }) => ({
    default: ProductsPage,
  })),
)

const ProductContainerPage = lazy(() =>
  import('pages/product/ProductContainer').then(({ ProductContainer }) => ({
    default: ProductContainer,
  })),
)

const OverviewPage = lazy(() =>
  import('pages/overview/OverviewPage').then(({ OverviewPage }) => ({
    default: OverviewPage,
  })),
)

const AdminPage = lazy(() =>
  import('pages/admin/AdminPage').then(({ AdminPage }) => ({
    default: AdminPage,
  })),
)

export const routes: AppRoute[] = [
  {
    Component: <MyProductsPage />,
    path: RoutesManager.myProducts.root.pattern,
  },
  {
    Component: <ProductsPageV2 />,
    path: RoutesManager.productsV2.root.pattern,
  },
  {
    Component: <ProductContainerPage />,
    path: RoutesManager.products.root.pattern,
  },
  {
    Component: <OverviewPage />,
    path: RoutesManager.overview.root.pattern,
  },
  {
    Component: <AdminPage />,
    path: RoutesManager.admin.root.pattern,
  },
  {
    Component: <CollectionsPage />,
    path: RoutesManager.collections.root.pattern,
  },
  {
    Component: <CollectionDetailsPage />,
    path: RoutesManager.collections.details.pattern,
  },
]
