import { LabelConfig, LabelLocales } from '@platform-ui-kit/components-library'
import { TooltipConfig } from '@platform-ui-kit/components-library/dist/types/types/common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const defaultTooltipConfig: TooltipConfig = {
  placement: 'right',
  trigger: 'mouseenter click',
}

export const useCommonLabelProps = ({
  labelConfig,
  labelTooltipConfig,
}: {
  labelConfig?: LabelConfig
  labelTooltipConfig?: TooltipConfig
}) => {
  const { t } = useTranslation()

  const labelLocales = useMemo<LabelLocales>(
    () => ({
      optional: t('label.optional'),
    }),
    [t],
  )

  let labelConfigResult = labelConfig
  let labelTooltipConfigResult = labelTooltipConfig

  if (labelConfigResult) {
    const { text, description, icon, locales = labelLocales } = labelConfigResult

    labelConfigResult = {
      text,
      description,
      locales,
      icon,
      ...(!icon && !!description && { icon: 'wpp-icon-info' }),
    }

    if (labelTooltipConfigResult) {
      const { placement, trigger, ...rest } = labelTooltipConfigResult
      labelTooltipConfigResult = {
        ...rest,
        placement: placement || defaultTooltipConfig.placement,
        trigger: trigger || defaultTooltipConfig.trigger,
      }
    } else {
      if (description) {
        labelTooltipConfigResult = defaultTooltipConfig
      }
    }
  }

  return { labelConfig: labelConfigResult, labelTooltipConfig: labelTooltipConfigResult }
}
