import { WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useStableCallback } from 'hooks/useStableCallback'
import { CollectionDTO, CollectionStatus } from 'types/collections/collection'
import { CollectionStatusLabel } from 'types/collections/labels'
import { Flex } from 'ui-base/flex/Flex'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { CollectionCardActions } from 'ui-shared/collectionCard/components/CollectionCardActions'
import { CollectionCardFooter } from 'ui-shared/collectionCard/components/CollectionCardFooter'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'ui-shared/collectionCard/CollectionCard.styled'

interface Props {
  collection: CollectionDTO
}

export const CollectionCard = ({ collection }: Props) => {
  const { t } = useTranslation(['collections'])
  const { id, name, elements, updatedAt, collectionStatus } = collection

  const navigate = useNavigate()

  const handleCardClick = useStableCallback(() => {
    navigate(RoutesManager.collections.details.getURL({ collectionId: id }))
  })

  return (
    <S.CollectionCard size="m" interactive onClick={handleCardClick} data-testid="collection-card">
      <S.CollectionNameWrapper gap={8} slot="header">
        <Tooltip
          show
          showOnOverflow
          getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
          text={name}
          slot="header"
        >
          <S.CollectionName type="l-strong" tag="h2" data-testid="collection-name">
            {name}
          </S.CollectionName>
        </Tooltip>
      </S.CollectionNameWrapper>

      <CollectionCardActions id={id} name={name} />

      <Flex grow={1} gap={4}>
        <WppTag
          variant="neutral"
          label={t('collections|products', { count: elements.length })}
          data-testid="collection-products-count"
        />
        <WppTag
          variant={collectionStatus === CollectionStatus.DRAFT ? 'neutral' : 'positive'}
          label={t(`collections|collection_status.${CollectionStatusLabel[collectionStatus]}`)}
          data-testid="collection-status"
        />
      </Flex>

      <CollectionCardFooter updatedAt={updatedAt} />
    </S.CollectionCard>
  )
}
