import styled from '@emotion/styled'
import { WppGrid } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const PageWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  padding: 24px 0;
`

export const CollectionsCardsWrapper = styled(WppGrid)`
  --wpp-grid-max-spacing: 12px;
  --wpp-grid-min-spacing: 12px;
`
