import { fetchCollectionApi } from 'api/collections/fetchers/fetchCollectionApi'
import { createUseFetch } from 'api/common/createUseFetch'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useCollectionApi = createUseQuery({
  queryKey: ApiQueryKeys.COLLECTION,
  fetcher: fetchCollectionApi,
  selector: res => res?.data || null,
})

export const useFetchCollection = createUseFetch({
  queryKey: ApiQueryKeys.COLLECTION,
  fetcher: fetchCollectionApi,
})
