import { WppBanner } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'

import { useIsActionPermitted } from 'hooks/useIsActionPermitted'
import { AccessActions } from 'types/permissions/enums'
import { RoutesManager } from 'utils/routesManager'

export const ViewOnlyBanner = () => {
  const { t } = useTranslation(['products'])
  const { pathname } = useLocation()
  const { isActionPermitted } = useIsActionPermitted()
  const isDetailsManagePermitted = isActionPermitted([AccessActions.PRODUCT_MANAGE])

  const isProductDetailsPage = matchPath(RoutesManager.products.details.pattern, pathname)?.params?.['*'] === ''

  const showDetailsPageBanner = isProductDetailsPage && !isDetailsManagePermitted

  return (
    <WppBanner type="information" show={showDetailsPageBanner}>
      {t('products|view_only_banner')}
    </WppBanner>
  )
}
