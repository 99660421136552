import styled from '@emotion/styled'
import { WppStep, WppStepper } from '@platform-ui-kit/components-library-react'

export const Stepper = styled(WppStepper)`
  margin-bottom: 40px;
`

export const Step = styled(WppStep)`
  &[active~='false'] {
    pointer-events: none;
  }
`
