import { forwardRef, SVGProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NoCodeAppType, ProductType, ProductTypeLabel } from 'types/products/enums'
import { FullAppType } from 'types/products/product'
import { Flex } from 'ui-base/flex/Flex'
import { ReactComponent as AISerciveAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/aiServiceAppIcon.svg'
import { ReactComponent as CodeUploadAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/codeUploadAppIcon.svg'
import { ReactComponent as EmbeddedCodeAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/embeddedCodeAppIcon.svg'
import { ReactComponent as EmbeddedLinkAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/embeddedLinkAppIcon.svg'
import { ReactComponent as ExternalLinkAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/externalLinkAppIcon.svg'
import { ReactComponent as MiroBoardAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/miroBoardAppIcon.svg'
import { ReactComponent as NativeAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/nativeAppIcon.svg'
import { ReactComponent as PageBuilderAppIcon } from 'ui-base/svgIcons/productTypeIcon/assets/pageBuilderAppIcon.svg'

import * as S from 'ui-base/svgIcons/productTypeIcon/ProductTypeIcon.styled'

interface Props extends SVGProps<SVGSVGElement> {
  productType: FullAppType
  size?: number
  iconColor?: string
  withLabel?: boolean
}

export const ProductTypeIcon = forwardRef<SVGSVGElement, Props>(
  ({ size, withLabel, productType, iconColor, ...rest }: Props, ref) => {
    const { t } = useTranslation(['products'])
    const IconComponent = useMemo(
      () => ({
        [ProductType.NATIVE_APPLICATION]: NativeAppIcon,
        [ProductType.AI_SERVICE_PRODUCT]: AISerciveAppIcon,
        [NoCodeAppType.EMBEDDED_CODE]: EmbeddedCodeAppIcon,
        [NoCodeAppType.EMBEDDED_LINK]: EmbeddedLinkAppIcon,
        [NoCodeAppType.MIRO_BOARD]: MiroBoardAppIcon,
        [NoCodeAppType.PAGE_BUILDER]: PageBuilderAppIcon,
        [NoCodeAppType.EXTERNAL_LINK]: ExternalLinkAppIcon,
        [NoCodeAppType.CODE_UPLOAD]: CodeUploadAppIcon,
      }),
      [],
    )[productType]

    if (!withLabel) {
      return <S.StyledIcon as={IconComponent} size={size} color={iconColor} ref={ref} {...rest} />
    }

    return (
      <Flex gap={6} data-testid="product-type">
        <S.StyledIcon as={IconComponent} size={size} ref={ref} {...rest} />
        <S.Label type="2xs-strong">{t(`products|product_type_names.${ProductTypeLabel[productType]}`)}</S.Label>
      </Flex>
    )
  },
)
