import { ListItemChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppListItemCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppActionButton,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppListItem,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCustomDialog } from 'hooks/useCustomDialog'
import { CollectionDeleteModal } from 'ui-shared/collectionCard/components/CollectionDeleteModal'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'ui-shared/productCard/ProductCard.styled'

interface Props {
  id: string
  name: string
}

export const CollectionCardActions = ({ id, name }: Props) => {
  const { t } = useTranslation(['common', 'products'])
  const navigate = useNavigate()
  const { open, handleOpen: handleOpenDeleteModal, handleClose: handleCloseDeleteModal } = useCustomDialog()

  const handleEdit = (e: WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    e.stopPropagation()
    navigate(RoutesManager.collections.details.getURL({ collectionId: id }))
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
      slot="actions"
    >
      <S.WppMenuContext listWidth="156px" dropdownConfig={{ placement: 'bottom-end' }}>
        <WppActionButton variant="secondary" slot="trigger-element" data-testid="collection-card-action-button">
          <WppIconMore direction="horizontal" color="var(--wpp-grey-color-600)" />
        </WppActionButton>
        <div>
          <WppListItem onWppChangeListItem={handleEdit} data-testid="collection-card-edit-button">
            <WppIconEdit slot="left" />
            <span slot="label">{t('common|edit')}</span>
          </WppListItem>
          <WppListItem onWppChangeListItem={handleOpenDeleteModal} data-testid="collection-card-delete-button">
            <WppIconTrash slot="left" />
            <span slot="label">{t('common|delete')}</span>
          </WppListItem>
        </div>
      </S.WppMenuContext>

      <CollectionDeleteModal collectionId={id} collectionName={name} open={open} handleClose={handleCloseDeleteModal} />
    </div>
  )
}
