import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const NothingFoundSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="80" cy="80" r="80" className="fillPrimary100" />
    <path
      d="M142.646 59.6949C143.09 58.0496 145.526 58.0496 145.969 59.6949L146.201 60.5572C146.362 61.1544 146.858 61.6152 147.485 61.7508L147.969 61.8554C149.754 62.2413 149.754 64.6806 147.969 65.0665L147.485 65.1711C146.858 65.3067 146.362 65.7675 146.201 66.3647L145.969 67.227C145.526 68.8723 143.09 68.8723 142.646 67.227L142.414 66.3647C142.253 65.7675 141.757 65.3067 141.13 65.1711L140.646 65.0665C138.861 64.6806 138.861 62.2413 140.646 61.8554L141.13 61.7508C141.757 61.6152 142.253 61.1544 142.414 60.5572L142.646 59.6949Z"
      className="fillPrimary400"
    />
    <ellipse cx="22.4607" cy="91" rx="3.9998" ry="4" className="fillPrimary200" />
    <ellipse cx="17.4608" cy="79" rx="1.9999" ry="2" className="fillPrimary200" />
    <ellipse cx="79.9998" cy="147" rx="2.99985" ry="3" className="fillPrimary200" />
    <path
      d="M85.6613 11.1558C85.2178 9.51056 82.7818 9.51056 82.3384 11.1558L82.106 12.0181C81.9451 12.6153 81.4494 13.0761 80.822 13.2118L80.3383 13.3163C78.5535 13.7022 78.5535 16.1415 80.3383 16.5274L80.822 16.632C81.4494 16.7677 81.9451 17.2284 82.106 17.8257L82.3384 18.6879C82.7818 20.3332 85.2178 20.3332 85.6613 18.6879L85.8936 17.8257C86.0546 17.2284 86.5502 16.7677 87.1777 16.632L87.6614 16.5274C89.4462 16.1415 89.4462 13.7022 87.6614 13.3163L87.1777 13.2118C86.5502 13.0761 86.0546 12.6153 85.8936 12.0181L85.6613 11.1558Z"
      className="fillPrimary300"
    />
    <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 75 20.3086)" className="fillPrimary400" />
    <rect x="37" y="54" width="68" height="80" rx="6" className="fillPrimary300" />
    <g opacity="0.5" filter="url(#filter0_f_17702_10440)">
      <path
        d="M64 114H120C123.314 114 126 111.314 126 108V59.0722C126 57.3713 125.278 55.7503 124.014 54.6124L111.711 43.5402C110.61 42.5487 109.18 42 107.698 42H64C60.6863 42 58 44.6863 58 48V108C58 111.314 60.6863 114 64 114Z"
        className="fillPrimary500"
      />
    </g>
    <path
      d="M64 114H120C123.314 114 126 111.314 126 108V50L110 34H64C60.6863 34 58 36.6863 58 40V108C58 111.314 60.6863 114 64 114Z"
      fill="white"
    />
    <path d="M110 50H126L110 34V50Z" className="fillPrimary300" />
    <g filter="url(#filter1_b_17702_10440)">
      <path
        d="M69.7165 103.433C89.4422 103.433 105.433 87.4422 105.433 67.7165C105.433 47.9908 89.4422 32 69.7165 32C49.9908 32 34 47.9908 34 67.7165C34 87.4422 49.9908 103.433 69.7165 103.433Z"
        fill="white"
        fillOpacity="0.4"
      />
    </g>
    <path
      d="M83.4266 58.336L79.0971 54.0065L69.7165 63.387L60.336 54.0065L56.0065 58.336L65.387 67.7165L56.0065 77.0971L60.336 81.4266L69.7165 72.046L79.0971 81.4266L83.4266 77.0971L74.046 67.7165L83.4266 58.336Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.1083 96.3241C77.1185 106.813 57.1843 105.695 44.4611 92.9719C30.513 79.0238 30.513 56.4093 44.4611 42.4611C58.4093 28.513 81.0238 28.513 94.9719 42.4611C107.695 55.1843 108.813 75.1185 98.3241 89.1083L126 116.784L118.784 124L91.1083 96.3241ZM90.6424 88.6424C79.0854 100.2 60.3477 100.2 48.7906 88.6424C37.2336 77.0854 37.2336 58.3477 48.7906 46.7906C60.3477 35.2336 79.0854 35.2336 90.6424 46.7906C102.2 58.3477 102.2 77.0854 90.6424 88.6424Z"
      className="fillPrimary500"
    />
    <defs>
      <filter
        id="filter0_f_17702_10440"
        x="45"
        y="29"
        width="94"
        height="98"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_17702_10440" />
      </filter>
      <filter
        id="filter1_b_17702_10440"
        x="18.6667"
        y="16.6667"
        width="102.099"
        height="102.1"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.66667" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_17702_10440" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_17702_10440" result="shape" />
      </filter>
    </defs>
  </S.CustomSvg>
)
