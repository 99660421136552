import styled from '@emotion/styled'
import { WppActionButton, WppAvatar } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'

export const DragHandle = styled(Flex)`
  cursor: grab;
`

export const ProductRowContent = styled(Flex, {
  shouldForwardProp: props => props !== 'isHighlighted' && props !== 'withDragNDrop',
})<{
  isHighlighted?: boolean
  withDragNDrop?: boolean
}>`
  padding: 12px 8px;
  background-color: ${({ isHighlighted }) => (isHighlighted ? 'var(--wpp-grey-color-200)' : 'transparent')};

  ${({ withDragNDrop }) =>
    withDragNDrop &&
    `
      &:hover,
      &[data-is-dragging] {
        ${DragHandle} {
          opacity: 0.8;
        }
      }

      &[data-is-dragging] {
        cursor: grab;
        box-shadow: var(--wpp-box-shadow-s)
      }
    `}
`

export const ProductLogo = styled(WppAvatar, {
  shouldForwardProp: props => props !== 'isDisabled',
})<{
  isDisabled?: boolean
}>`
  flex-shrink: 0;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

export const NameWrapper = styled(Flex, {
  shouldForwardProp: props => props !== 'isDisabled',
})<{
  isDisabled?: boolean
}>`
  min-width: 0;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

export const Description = styled(Truncate)`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`

export const ActionButtonWrapper = styled(Flex)`
  flex-shrink: 0;
`

export const ActionButton = styled(WppActionButton)`
  flex-shrink: 0;
`
