import { ColDef as LibColDef, GetRowIdFunc, ICellRendererParams, IDatasource } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ComponentPropsWithoutRef, forwardRef, Ref, useCallback, useMemo, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { SortOrder } from 'api/common/types'
import { MayBeNull } from 'types/common/utils'
import { AgGridTable } from 'ui-base/agGridTable/AgGridTable'
import { CustomParams, TABLE_ROW_HEIGHT } from 'ui-base/dataTable/DataTable'
import {
  DEFAULT_CACHE_BLOCK_SIZE,
  DEFAULT_CACHE_OVERFLOW_SIZE,
  DEFAULT_INITIAL_ROW_COUNT,
  getCellRenderer,
  getValueGetter,
  NoRowsOverlayComponent,
  TableInfiniteLoader,
  TableInfiniteOnLoadError,
  TableInfiniteOnLoadSuccess,
} from 'ui-base/dataTable/InfiniteTable/utils'
import { isNumber } from 'utils/common'

import * as S from 'ui-base/dataTable/DataTable.styled'

export interface ColDef<TData = any> extends Omit<LibColDef<TData>, 'cellRenderer' | 'cellRendererParams'> {
  cellRenderer?: (params: ICellRendererParams<TData>) => MayBeNull<JSX.Element>
  cellRendererParams?: (
    params: ICellRendererParams<TData> & { data: TData extends any[] ? TData[number] : TData },
  ) => CustomParams
  loadingCellRenderer?: (params: ICellRendererParams<TData>) => JSX.Element
}

export type TableProps<TData = any> = Omit<
  ComponentPropsWithoutRef<typeof AgGridReact<TData>>,
  | 'loadingOverlayComponent'
  | 'noRowsOverlayComponent'
  | 'columnDefs'
  | 'noRowsOverlayComponent'
  | 'datasource'
  | 'rowData'
  | 'rowModelType'
> & {
  //loader function is used to create datasource
  loader: TableInfiniteLoader<TData>
  onLoadSuccess?: TableInfiniteOnLoadSuccess<TData>
  onLoadError?: TableInfiniteOnLoadError
  columnDefs: ColDef<TData>[]
  noRowsOverlayComponent?: NoRowsOverlayComponent<TData>
  'data-testid'?: string
  rowHeight?: number
}

export const InfiniteTable = forwardRef(function InfiniteTable<TData = any>(
  {
    columnDefs,
    cacheOverflowSize = DEFAULT_CACHE_OVERFLOW_SIZE,
    infiniteInitialRowCount = DEFAULT_INITIAL_ROW_COUNT,
    cacheBlockSize = DEFAULT_CACHE_BLOCK_SIZE,
    loader,
    onLoadError,
    onLoadSuccess,
    getRowId,
    'data-testid': dataTestId,
    rowHeight = TABLE_ROW_HEIGHT,
    ...props
  }: TableProps<TData>,
  ref: Ref<AgGridReact<TData>>,
) {
  const innerRef = useRef<AgGridReact<TData>>(null)

  const datasource = useMemo<IDatasource>(() => {
    let timestamp = Date.now()

    return {
      async getRows({ startRow, endRow, successCallback, failCallback, sortModel }) {
        const operationStartTimestamp = timestamp
        const isDiscarded = () => operationStartTimestamp !== timestamp

        const hideOverlay = () => {
          innerRef.current?.api.hideOverlay()
        }

        const showNoRowsOverlay = () => {
          innerRef.current?.api.showNoRowsOverlay()
        }

        const isInitialLoading = startRow === 0

        try {
          hideOverlay()

          const { data, totalRowsCount } = await loader({ startRow, endRow, sortModel })

          if (isDiscarded()) {
            successCallback([], 0)
          } else {
            if (!data.length) {
              if (isInitialLoading) {
                showNoRowsOverlay()
              }

              successCallback([], 0)
            } else {
              hideOverlay()
              successCallback(data, totalRowsCount <= endRow ? totalRowsCount : undefined)
            }

            onLoadSuccess?.({
              startRow,
              endRow,
              sortModel,
              data,
              totalRowsCount,
              isEmptyDataSource: startRow === 0 && !totalRowsCount,
            })

            try {
              const renderedNodes = innerRef.current?.api.getRenderedNodes()

              const shouldReflowRows = renderedNodes?.some(
                ({ rowTop, rowHeight }, index, arr) =>
                  index !== 0 &&
                  isNumber(rowTop) &&
                  isNumber(rowHeight) &&
                  rowTop - rowHeight !== arr[index - 1].rowTop,
              )

              if (shouldReflowRows) {
                innerRef.current?.api.redrawRows({ rowNodes: renderedNodes })
              }
            } catch (e) {
              if (process.env.DEV) {
                console.error('Rows reflow failed.')
              }
            }
          }
        } catch (e) {
          if (process.env.DEV) {
            console.error(e)
          }

          if (!isDiscarded()) {
            hideOverlay()
          }

          failCallback()
          onLoadError?.(e)
        }
      },
      destroy() {
        timestamp = Date.now()
      },
    }
  }, [loader, onLoadError, onLoadSuccess])

  const columnDefsInner = useMemo<ColDef<TData>[]>(
    () =>
      columnDefs.map(colDef => {
        const { valueGetter, ...rest } = colDef

        return {
          ...rest,
          sortingOrder: ['asc', 'desc', null] as SortOrder[],
          ...(!!valueGetter && { valueGetter: getValueGetter<TData>(valueGetter) }),
          cellRenderer: getCellRenderer<TData>(colDef),
        }
      }),
    [columnDefs],
  )

  const getRowIdInner: GetRowIdFunc<TData> = useCallback(params => getRowId?.(params)!, [getRowId])

  return (
    <S.TableBody direction="column" data-testid={dataTestId}>
      <AgGridTable
        {...props}
        ref={mergeRefs([ref, innerRef])}
        rowHeight={rowHeight}
        columnDefs={columnDefsInner!}
        rowModelType="infinite"
        rowSelection="multiple"
        suppressRowClickSelection
        cacheBlockSize={cacheBlockSize}
        infiniteInitialRowCount={infiniteInitialRowCount}
        cacheOverflowSize={cacheOverflowSize}
        datasource={datasource}
        enableCellTextSelection
        {...(!!getRowId && { getRowId: getRowIdInner })}
        //to avoid console.warn() about custom columnDefs property in jest
        suppressPropertyNamesCheck
      />
    </S.TableBody>
  )
}) as <TData = any>(props: { ref?: Ref<AgGridReact<TData>> } & TableProps<TData>) => JSX.Element
