import { WppIconTrash } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useCollectionData } from 'hooks/useCollectionData'
import { useCustomDialog } from 'hooks/useCustomDialog'
import { CollectionHeader } from 'pages/collections/common/collectionHeader/CollectionHeader'
import { SidebarMenu } from 'pages/collections/details/components/sidebarMenu/SidebarMenu'
import { CollectionProducts } from 'pages/collections/details/tabs/products/CollectionProducts'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'
import { CollectionDeleteModal } from 'ui-shared/collectionCard/components/CollectionDeleteModal'
import { HashRoute } from 'ui-shared/hashRoute/HashRoute'
import { handleApiErrors } from 'utils/api'

import * as S from 'pages/collections/details/CollectionDetailsPage.styled'

export enum CollectionDetailsTabs {
  PRODUCTS = 'PRODUCTS',
  AVAILABILITY = 'AVAILABILITY',
}

export const CollectionDetailsPage = () => {
  const { t } = useTranslation(['collections'])
  const { collection, isCollectionLoading, isCollectionError, collectionError } = useCollectionData()
  const { open, handleOpen: handleOpenDeleteModal, handleClose: handleCloseDeleteModal } = useCustomDialog()

  if (isCollectionLoading) {
    return <CustomWppSpinner size="l" data-testid="collection-details-page-spinner" />
  }

  if (isCollectionError) {
    return handleApiErrors({ error: collectionError })
  }

  return (
    <S.PageWrapper direction="column" gap={24}>
      <CollectionHeader
        renderRight={
          <S.DeleteCollectionButton
            variant="secondary"
            onClick={handleOpenDeleteModal}
            data-testid="delete-collection-button"
          >
            <WppIconTrash slot="icon-start" />
            {t('collections|delete_collection')}
          </S.DeleteCollectionButton>
        }
      />

      <S.PageContent gap={28}>
        <SidebarMenu />

        <HashRoute
          defaultRoute={CollectionDetailsTabs.PRODUCTS}
          routeMap={{
            [CollectionDetailsTabs.PRODUCTS]: () => <CollectionProducts />,
          }}
        />
      </S.PageContent>

      <CollectionDeleteModal
        collectionId={collection.id}
        collectionName={collection.name}
        open={open}
        handleClose={handleCloseDeleteModal}
        redirectToCollectionsAfterDelete
      />
    </S.PageWrapper>
  )
}
