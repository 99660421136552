import { useQueryClient } from '@tanstack/react-query'

import { createCollectionApi } from 'api/collections/fetchers/createCollectionApi'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useCreateCollectionApi = createUseMutation({
  fetcher: createCollectionApi,
})

export const useCreateCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useCreateCollectionApi({
    onSuccess: async response => {
      await Promise.all([
        queryClient.setQueryData([ApiQueryKeys.COLLECTION, { collectionId: response.data.id }], response),
        queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS]),
      ])
    },
  })
}
