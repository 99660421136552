import styled from '@emotion/styled'
import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const SelectedProductsAccordion = styled(WppAccordion)`
  &::part(content) {
    margin-left: 0;
  }
`

export const SelectedProductsWrapper = styled(Flex)`
  min-width: 0;
`

export const NoSelectedProducts = styled(WppTypography)`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`
