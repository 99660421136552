import styled from '@emotion/styled'
import { WppInput } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const StepWrapper = styled(Flex)`
  width: 100%;
  flex-shrink: 0;
`

export const SearchInput = styled(WppInput)`
  width: 268px;
`

export const ProductsWithVersionsListWrapper = styled(Flex)`
  padding-bottom: 12px;
`
