import { WppButton, WppIconPlus } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from 'ui-base/emptyState/EmptyState'
import { NoTableContentSvg } from 'ui-base/svgIcons/emptyState/assets/NoTableContentSvg'

interface Props {
  handleOpenUpdateCollectionModal: () => void
}

export const TableEmptyState = ({ handleOpenUpdateCollectionModal }: Props) => {
  const { t } = useTranslation(['collections'])

  return (
    <EmptyState
      message={{ title: t('collections|collection_details.products_table.empty_state.title') }}
      image={<NoTableContentSvg width={120} height={120} data-testid="empty-state-image" />}
      showImage
      data-testid="collections-empty-products-list"
    >
      <WppButton onClick={handleOpenUpdateCollectionModal} data-testid="empty-state-add-product-button">
        <WppIconPlus slot="icon-start" />
        {t('collections|collection_details.products_table.empty_state.button')}
      </WppButton>
    </EmptyState>
  )
}
