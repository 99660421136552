import { useGetDownloadUrlsApi } from 'api/attachments/queries/useGetDownloadUrlsApi'
import { useProductCategories } from 'hooks/useProductCategories'
import { ImageMetaDTO } from 'types/common/attachments'
import { API } from 'types/common/enums'
import { defaultAiServiceLogoUrl, ProductType } from 'types/products/enums'
import { getKeysFromImageMeta } from 'utils/attachments'

interface Props {
  logoMeta?: ImageMetaDTO
  categoryLogoUrl?: string
  customDefaultLogoUrl?: string
  productType: ProductType
}

export const useAppLogoUrl = ({ logoMeta, categoryLogoUrl, customDefaultLogoUrl, productType }: Props) => {
  const { defaultCategory } = useProductCategories()
  const { data: applicationLogo } = useGetDownloadUrlsApi({
    params: {
      api: API.DEVHUB,
      keys: getKeysFromImageMeta(logoMeta),
    },
    enabled: !!logoMeta?.thumbnail,
  })
  //we should show logo of 'other apps' category for products with category (native/no-code) and predefined logo for 'ai-service' by default
  //it is logo fallback if for some reason there is no categoryLogoUrl/customDefaultLogoUrl (it shouldn't happen)
  const productCategoryLogoUrl = categoryLogoUrl || defaultCategory?.logoUrl
  const aiServiceProductDefaultLogo = customDefaultLogoUrl || defaultAiServiceLogoUrl
  const defaultLogoUrl =
    productType === ProductType.AI_SERVICE_PRODUCT ? aiServiceProductDefaultLogo : productCategoryLogoUrl

  // custom logo can be added only to 'no-code' or 'ai-service' products
  const logoThumbnailUrl = applicationLogo?.[0]?.signedUrl
  const customLogoUrl = logoThumbnailUrl
  const showCustomLogo = !!customLogoUrl

  return showCustomLogo ? customLogoUrl : defaultLogoUrl
}
