import qs from 'qs'

import { devHubApi } from 'api'
import { ApiRequestSortParameters, ApiRequestPaginationParameters, Paginated } from 'types/api/api'
import { CollectionDTO } from 'types/collections/collection'

type Params = ApiRequestPaginationParameters & ApiRequestSortParameters<CollectionDTO, true>

export const fetchInfiniteCollections = ({ sort, size, page }: Params) =>
  devHubApi.post<Paginated<CollectionDTO>>(
    '/collections/my',
    {},
    {
      params: {
        page,
        size,
        sort,
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
    },
  )
