import { WppIconTick, WppTag } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { CollectionStatus } from 'types/collections/collection'
import { CollectionStatusLabel } from 'types/collections/labels'

interface Props {
  status: MayBeNull<CollectionStatus>
}

export const CollectionStatusTag = ({ status }: Props) => {
  const { t } = useTranslation(['collections'])

  if (!status) {
    return null
  }

  const isNotDraft = status !== CollectionStatus.DRAFT
  const variant = isNotDraft ? 'positive' : 'neutral'

  return (
    <WppTag
      variant={variant}
      label={t(`collections|collection_status.${CollectionStatusLabel[status]}`)}
      data-testid="collection-status"
    >
      {isNotDraft && <WppIconTick slot="icon-start" />}
    </WppTag>
  )
}
