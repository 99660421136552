import { fetchInfiniteProductsWithVersions } from 'api/collections/fetchers/fetchInfiniteProductsWithVersions'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteProductsWithVersionsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.PRODUCTS_WITH_VERSIONS,
  fetcher: fetchInfiniteProductsWithVersions,
  selector: res => res?.pages?.flatMap(page => page.data?.content) || [],
  getNextPageParam: lastPage => (lastPage?.data?.last ? undefined : lastPage?.data?.number + 1),
})
