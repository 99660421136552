import { useEffect } from 'react'
import { useController, useFormContext, UseControllerProps } from 'react-hook-form'

type Params = UseControllerProps

export const useField = ({ name, ...rest }: Params) => {
  const { setValue } = useFormContext()
  const controller = useController({ name, ...rest })

  const {
    field: { value },
    fieldState: { error, isTouched },
  } = controller

  /*
    react-hook-form validates all fields on submit
    but doesn't touch them due to their architecture.
    Each field covers this case individually.
  */
  useEffect(() => {
    if (error && !isTouched) {
      setValue(name, value, { shouldTouch: true })
    }
  }, [name, value, error, isTouched, setValue])

  return controller
}
